<template>
  <li class="v-total-summary-single-summary-type">
    <span
      class="v-total-summary-single-summary-text"
      v-html="sanitize(text)"
    />
    <span
      class="v-total-summary-single-summary-value"
      :class="{
        'v-error-color': bonusWithdraw,
        'v-success-color': bonusAccrual
      }"
    >
      <common-currency
        v-if="negateCost"
        class="v-free-delivery__warning v-mr-xs"
        :amount="amountModified"
      />
      <span
        v-if="negateCost ? amount > 0 : sign"
        v-html="sanitize(sign)"
      />
      <common-currency
        :class="{
          'v-strikethrough-delivery': negateCost,
          hidden: negateCost && amount === 0 && amountModified === amount
        }"
        :amount="negateCost || amountModified === -1 ? amount : amountModified"
        :show-points="bonusWithdraw || bonusAccrual"
      />
    </span>
  </li>
</template>

<script setup lang="ts">
const {
  amount = 0,
  amountModified = -1,
  bonusAccrual = false,
  bonusWithdraw = false,
  negateCost = false,
  sign = '',
  text = ''
} = defineProps<{
  amount?: number
  amountModified?: number
  text?: string
  sign?: string
  bonusWithdraw?: boolean
  bonusAccrual?: boolean
  negateCost?: boolean
}>()

const { sanitize } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/variables';

.v-total-summary-single-summary-type {
  display: flex;
  font-size: 1.4rem;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  .v-amount {
    font-weight: 600;
  }

  .v-currency-wrapper {
    font-weight: 600;
  }

  &:after {
    content: '';
    height: 1px;
    width: 100%;
    position: relative;
    bottom: 6px;
    border-bottom: 1px dashed variables.$BorderColorLight;
    z-index: 1;
  }

  span {
    position: relative;
    z-index: 2;
    background: variables.$BodyElementsBackground;
  }

  .v-total-summary-single-summary-value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    .v-currency-wrapper {
      display: flex;
      align-items: center;
      &.v-free-delivery__warning {
        color: variables.$WarningForeColor;
        background: variables.$WarningColor;
        border-radius: 20px;
        padding: 4px 10px;
        font-weight: 500;
        font-size: 1.125rem;
        > span {
          background: variables.$WarningColor;
        }
      }
    }

    .v-strikethrough-delivery {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        bottom: 50%;
        transform: translateY(50%);
        left: 0;
        right: 0;
        z-index: 3;
        height: 2px;
        background: variables.$PopupColor;
      }
    }
  }
}
</style>
